import React, { useState, useEffect } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.jpg";
import ContactImg2 from "../../assets/img/contact-2.jpg";
import ContactImg3 from "../../assets/img/contact-3.jpg";

import connector from "../../helpers/connector"
import InputMask from 'react-input-mask';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function Contact() {
  var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Form/Submit";

  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [validator, setValidator] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const [isSend, setIsSend] = useState(false);

  var data = {
    domain: window.location.protocol + '//' + window.location.hostname,
    formSystemName: "RealAccountForm",
    formValues: {
      "fullName": fullname,
      "email": email,
      "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
      "country": "Turkey",
      "countryCode": "+90",
      "approvedConditions": true,
      "message": message
    },
    languageIsoCode: 'tr',
    siteSystemName: 'ARRAY-Danismanlik',

  }

  useEffect(() => {
    if (fullname === "" || email === "" || phone === "" || message === "" || phone.includes("_") || validator === false) {
      setIsNull(false)
    }
    else {
      setIsNull(true)
    }
  }, [fullname, email, phone, message, validator])

  function EmailChange(e) {
    const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let isOk = validEmail.test(String(e).toLowerCase())
    if (isOk) {
      setEmail(e);
      setValidator(true)
    }
    else {
      setEmail(e);
      setValidator(false)
    }
  }

  async function Submit() {
    setIsSend(true)
    connector(formUrl, data)
      .then(data => {
        if (data.status === 1) {
          NotificationManager.success('En kısa süre içerisinde geri dönüş sağlayacağız', 'Mail İsteğiniz Alındı!');
          setIsSend(false)
        }
        else {
          NotificationManager.error('Lütfen sayfayı yenileyip tekrar deneyin.', 'Bir Hata Oluştu!');
          setIsSend(false)
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            NotificationManager.error('Lütfen sayfayı yenileyip tekrar deneyin.', 'Bir Hata Oluştu!');
            setIsSend(false)
          }
          else {
            NotificationManager.success('En kısa süre içerisinde geri dönüş sağlayacağız', 'Mail İsteğiniz Alındı!');
            setIsSend(false)
          }
        }
        else {
          NotificationManager.error('Lütfen sayfayı yenileyip tekrar deneyin.', 'Bir Hata Oluştu!');
          setIsSend(false)
        }
      })
    setFullName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Bizimle İletişime Geçin</h1>
            <p className="font15">
              Hizmetlerimiz Hakkında Bilgi almak için Formu Doldurmanız Yeterlidir …
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-11 col-sm-11 col-md-6 col-lg-6">
              <Form>
                <label className="font13">İsim Soyisim:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" value={fullname} onChange={event => { setFullName(event.target.value) }} />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" value={email} onChange={event => { EmailChange(event.target.value) }} />
                <label className="font13">Telefon:</label>
                <InputMask mask="(999) 999 99 99" type="phone" id="phone" name="phone" className="font20 extraBold" value={phone} onChange={event => { setPhone(event.target.value) }} />
                <label className="font13">Mesaj:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" value={message} onChange={event => { setMessage(event.target.value) }} />
              </Form>
              <SumbitWrapper className="flex">
                {isNull ?
                  <ButtonInput onClick={() => { Submit() }} className="pointer animate radius8" style={{ maxWidth: "220px", textAlign: "center" }} >GÖNDER</ButtonInput>
                  :
                  <ButtonDisableInput className="pointer animate radius8" style={{ maxWidth: "220px", textAlign: "center"}} >
                    {isSend ? "Gönderiliyor..." : "GÖNDER"}
                  </ButtonDisableInput>}
              </SumbitWrapper>
            </div>
            <div className="col-xs-7 col-sm-7 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.button`
  border: 1px solid #0b72ba;
  background-color: #0b72ba;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0c639f;
    border: 1px solid #0b72ba;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ButtonDisableInput = styled.button`
  border: 1px solid #999;
  background-color: #999;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









