import ProjectImg1 from "../../assets/img/projects/network.jpg";
import ProjectImg2 from "../../assets/img/projects/santral.jpg";
import ProjectImg3 from "../../assets/img/projects/software.jpg";
import ProjectImg4 from "../../assets/img/projects/security.jpg";
import ProjectImg5 from "../../assets/img/projects/hosting.jpg";
import ProjectImg6 from "../../assets/img/projects/project.jpg";
import ProjectImg7 from "../../assets/img/projects/crm.jpg";

const ServiceList = [
    {
        image: ProjectImg7,
        title: 'CRM',
        description: 'Mevcut ve potansiyel müşterilerinizle olan tüm etkileşimlerinize yardımcı olacak Müşteri İlişkileri Yönetimi (CRM) konusunda sizlere destek oluyoruz.',
        detail:"<p>Müşteri İlişkileri Yönetimi (CRM), şirketlerin tüm müşteri etkileşimlerinin kaydedilmesi sonucu ortaya çıkan veriler sayesinde, şirketlerin ticari ilişkileri güçlendirmek ve karlılığı arttırmak için kullandıkları teknoloji, strateji ve uygulamaların bütünüdür.</p><p>Mevcut ve potansiyel müşterilerinizle olan tüm etkileşimlerinize yardımcı olacak Müşteri İlişkileri Yönetimi (CRM) konusunda sizlere destek oluyoruz.</p>"
    },
    {
        image: ProjectImg2,
        title: 'Santral',
        description: 'Cep telefonundan, PC’den veya ofisteki IP telefonundan aynı hatta bağlanarak erişim sağlayabileceğiniz Santral konusunda çözümler üretiyoruz.',
        detail:"<p> Teknolojik gelişme sonucunda ortaya çıkmış olan IP santral, günümüzde faydalı olma yolunda ilerleme kaydediyor. İnsanların işlerini kolaylaştırır şekilde üretilmiş olan IP santral, her yerde iletişim gerçekleştirilmesine imkan sağlıyor. Bu da tercih edilmesindeki en önemli sebeplerden biri oluyor.</p><p>Cep telefonundan, PC’den veya ofisteki IP telefonundan aynı hatta bağlanarak erişim sağlayabileceğiniz Santral konusunda çözümler üretiyoruz.</p>"
    },
    {
        image: ProjectImg4,
        title: 'Siber Güvenlik',
        description: 'Array Danışmanlık olarak, gerek Fortinet cihazları arasında gerek farklı marka firewall cihazlarından Fortigate ürünlerine konfigürasyon işlemlerinde hızlı, güvenli, müşteri memnuniyetini esas alan taşıma hizmeti sunmaktayız.',
        detail:"<p>Siber güvenlik; bilgisayarları, sunucuları, mobil cihazları, elektronik sistemleri, ağları ve verileri kötü amaçlı saldırılardan koruma uygulamasıdır. Bilgi teknolojisi güvenliği veya elektronik bilgi güvenliği olarak da bilinir.</p><p>Array Danışmanlık olarak, gerek Fortinet cihazları arasında gerek farklı marka firewall cihazlarından Fortigate ürünlerine konfigürasyon işlemlerinde hızlı, güvenli, müşteri memnuniyetini esas alan taşıma hizmeti sunmaktayız.</p>"
    },
    {
        image: ProjectImg1,
        title: 'Network',
        description:'Altyapı ve kablolama çözümleri, network cihazlarının seçimi ve VPN hizmetlerini Array Danışmanlık ile karşılayabilirsiniz.',
        detail:"<p>Network, uzak veya kısa mesafedeki bilgisayarları veya iletişim araçlarını birbirine kablolu veya kablosuz olarak baglanarak veri alışverisinin saniyeler içerisinde gerçekleştiği sistemdir.</p><p>Altyapı ve kablolama çözümleri, network cihazlarının seçimi ve VPN hizmetlerini Array Danışmanlık ile karşılayabilirsiniz.</p>"
    },
    {
        image: ProjectImg5,
        title: 'Web Hosting',
        description: 'Web tasarım hizmetlerini, web domain ve hosting hizmetlerini siz müşterilerimize kusursuz bir şekilde sağlamaktayız.',
        detail:"<p>İnternet siteleri de tıpkı gerçek hayattaki siteler gibi bir arazi üzerine kurulurlar. Bir internet sitesini yayınlamak için gerekli olan sanal araziye “hosting” adı verilir. İngilizce bir terim olan hosting, dilimize “web barındırma alanı” ya da “barındırma alanı” olarak çevrilebilir.</p><p>Web tasarım hizmetlerini, web domain ve hosting hizmetlerini siz müşterilerimize kusursuz bir şekilde sağlamaktayız.</p>"
    },
    {
        image: ProjectImg3,
        title: 'Yazılım',
        description: 'Sizler için yaratıcı çözümleri ve kolay kullanımıyla, ürettiğimiz web uygulamalarımızı iş takibi ve gelir arttırmak amacı ile kullanabilirsiniz.',
        detail:"<p>Bilgisayar teknolojilerinin öne çıktığı zamanımızda oldukça önemli öğelerin başında yazılımlar gelmektedir. Özellikle bilgisayar ve benzeri cihazların kullanımında yazılımlar hayati niteliktedir.</p><p>Sizler için yaratıcı çözümleri ve kolay kullanımıyla, ürettiğimiz web uygulamalarımızı iş takibi ve gelir arttırmak amacı ile kullanabilirsiniz.</p>"
    },
    {
        image: ProjectImg6,
        title: 'Proje Yönetimi',
        description: 'Array Danışmanlık olarak, kurum veya işletmenin sürdürdüğü işlerin daha hızlı, etkin ve verimli hale gelmesi için bilgi teknolojileri kullanılarak hazırlanan proje yönetimi konusunda size destek vermekteyiz.',
        detail:"<div className='modal'><p>Projenin hazırlanmasından önce gerekli bilgilerin elde edilmesi için bir durum analizinin yapılması, sorunların çözümlenmesi ve önerilen işlerin hedeflerinin tanımlanması için gerekli işlemleri oluşturur. Başlıca yöntemleri:</p><ul><li>Risk Yönetimi</li><li>Kalite Yönetimi</li><li>Zaman ve Maliyet Yönetimi</li><li>İletişim Yönetimi</li><li>Tedarik Yönetimi</li></ul><p>olarak detaylandırılabilir.</p><p>Array Danışmanlık olarak, kurum veya işletmenin sürdürdüğü işlerin daha hızlı, etkin ve verimli hale gelmesi için bilgi teknolojileri kullanılarak hazırlanan proje yönetimi konusunda size destek vermekteyiz.</p><div>"
    },

]
export default ServiceList